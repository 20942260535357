import { Observable } from 'rxjs';
import { UploadMassivePayload } from '../dto/massive-upload.dto';

export abstract class ManagementFileRepository {
  abstract massive(payload: UploadMassivePayload): Observable<any>;

  abstract massivePrivateFile(payload: UploadMassivePayload): Observable<any>;

  abstract downloadPrivateFile(fileId: number): Observable<any>;

}